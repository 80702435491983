.about__container {
    display: grid;
    grid-template-columns: 30% 50%;
    gap: 20%;
}

.about__section {
    height: 850px;
    padding-top: 120px;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: grid;
    place-items: center;
}

.about__content {
    margin: auto;
  width: 100%;
  padding: 10px;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    width: 100%;
    transition: var(--transition);
}

.about__me-image:hover {
    transform: rotate(0);
}

.about__cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about__card{
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
    margin-bottom: 1rem;
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary);
}

.about__icon{
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5 {
    font-size: 1rem;
}

.about__card small {
    color: var(--color-light);
    font-size: 0.9rem;
}

.about__content p {
    color: var(--color-light);
    margin-bottom: 1.5rem;
}

section h5 {
    font-size: 0.8rem;
}

section h2 {
    font-size: 1.8rem;
}

@media screen and (max-width: 1950px) {
    .about__section {
        height: 600px;
    }
}

@media screen and (max-width: 1700px) {
    .about__section {
        height: 660px;
    }
}

@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }

    .about__section {
        height: 1100px;
        padding-top: 60px;
    }
}

@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__cards{
        grid-template-columns: 1fr 1fr;
    }

    .about__content{
        text-align: center;
    }

    .about__content p{
        margin: 1.5rem 0;
    }
}