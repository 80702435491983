@import url('https://fonts.googleapis.com/css2?family=Quantico:wght@400;700&display=swap');

/* initialization */
*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    box-sizing: 0;
    list-style: none;
    text-decoration: none;
}

/** global variables **/
:root{
    --color-bg: #0D0D0D;
    --color-bg-variant: #272727;
    --color-primary: #00CE1B;
    --color-primary-variant: rgba(0, 205, 27, 0,4);
    --color-white: #EBEBEB;
    --color-light: #B2B1B1;

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-ms: 90%;
}

::-webkit-scrollbar{
    display: none;
}

html{
    
    scroll-behavior: smooth;
}

body{
    font-family: 'Quantico', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1,7px;
/**    background-image: url(../src/assets/bg-texture.png); **/
}

/** ========== General Styles =========== **/

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5{
    font-weight: 500;
}

h1{
    font-size: 2,5rem;
}

section {
    margin-top: 8rem;
    height: 800px;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    background-color: var(--color-bg-variant);
    padding: 5px;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover{
    background: var(--color-bg-variant);
    border-color: transparent;
}

.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg);
    border-radius: 0.7rem;
    padding: 0.8rem;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* media queries Tablet */

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
        height: 1000px;   
    }
}

/* media queries Mobile devices */

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-ms);
    }

    section > h2{
        margin-bottom: 2rem;   
    }
}
