.container.contact__container {
    width: 900px;
    display: grid;
    grid-template-columns: 30% 60%;
    gap: 2rem;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background-color: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transform: var(--transition);
}

.contact__option h5 {
    font-size: 1rem;
}

.contact__option a {
    font-size: 0.8rem;
    margin-top: 0.7rem;
    display: inline-block;
}

.contact__option:hover {
    background: transparent;
    border-color: var(--primary-variant);
}

.contact__icon {
    font-size: 2rem;
}


/* Form */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 1px solid var(--color-primary);
    resize: none;
    color: var(--color-white);
}

@media screen and (max-width: 1024px) {
    .container.contact__container {
        width: 50%;
        padding-bottom: 200px;
        grid-template-columns: 1fr;
        gap: 2rem;
    }

}


@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-md);
    }
   
}
