.skills__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.skills__section {
    height: 640px;
}

.skills__container > div {
    background-color: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.skills__container > div:hover {
    background: transparent;
    border-color: var(--color-primary);
}

.skills__container > div h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
    font-size: 1.5rem;
}

.skills__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.skills__details {
    display: flex;
    gap: 1rem;
}

.skills__details-icon {
    margin-top: 6px;
    color: var(--color-primary);
}

@media screen and (max-width: 1024px) {
    .skills__container {
        grid-template-columns: 1fr;
    }

    .skills__section {
        height: 900px;
    }

    .skills__container > div {
        width: 90%;
        padding: 2rem;
        margin: 0 auto;
    }
}
