.portfolio__section {
    height: 1100px;
}

.portfolio__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    align-content: center;
    max-width: 80%;
}

.portfolio__container h3 {
    padding: 1rem;
}

.portfolio__item {
    background-color: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    overflow: hidden;
    border-radius: 1.5em;
}

@media screen and (max-width: 1700px) {
    .portfolio__container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 1500px) {
    .portfolio__section {
        height: 1000px;
    }
}

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
    }

    .portfolio__section {
        height: 1600px;
    }
}

@media screen and (max-width: 850px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        max-width: 500px;
    }
    .portfolio__section {
        height: 3700px;
    }
}


@media screen and (max-width: 600px) {
    
    .portfolio__container {
        grid-template-columns: 1fr;
        max-width: 80%;
    }

    .portfolio__section {
        height: 3500px;
    }
}

@media screen and (max-width: 560px) {

    .portfolio__section {
        height: 3300px;
    }
}

@media screen and (max-width: 530px) {

    .portfolio__section {
        height: 3200px;
    }
}

@media screen and (max-width: 490px) {

    .portfolio__section {
        height: 2700px;
    }
}