#header {
    height: 90vh;
}

.header__container {
    display: grid;
    grid-template-columns: 40% 50%;
    gap: 10%;
    place-items: center;
    margin: auto;
    padding: auto;
}

.header__image img {
    max-width: 800px;
    animation: floatImage 4s ease-in-out infinite;
    animation-delay: 2s, 3s;
}

.header__info {
    max-width: 100%;
}

.header__info h1 {
    font-size: 70px;
    color: var(--color-primary);
}

.header__info h2 {
    font-size: 30px;
}

.header__info h2 {
    padding-top: 20px;
}

.header__info span {
    color: var(--color-primary);
}

.social__icons a {
    display: inline-flex;
    width: 40px;
    height: 40px;
    background: transparent;
    border: 2px solid var(--color-primary);
    border-radius: 50%;
    font-size: 20px;
    color: white;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    margin: 30px 15px 30px 0;
    transition: var(--transition);
}

.social__icons a:hover {
    background-color: var(--color-primary);
    color: black;
    box-shadow: 0 0 20px cyan;
}

@keyframes floatImage {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-24px);
    }
    100% {
        transform: translateY(0);
    }
}

@media screen and (max-width: 2000px) {
    .header__image img {
        max-width: 600px;
    }
}

@media screen and (max-width: 1300px) {
    .header__info h2 {
        font-size: 20px;
    }
    .header__info h1 {
        font-size: 50px;
    }

    #header {
        height: 550px;
    }

}

@media screen and (max-width: 800px) {
    .header__container {
        grid-template-columns: 1fr;
    }

    #header {
        height: 1200px;
        padding: 10%;
    }
}

@media screen and (max-width: 600px) {
    #header {
        height: 1000px;
        padding: 5%;
    }
}