.experience__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;
    padding-top: 2em;
    max-width: 90%;
}

.experience__section {
    height: 700px;
}

.experience {
    background: var(--color-bg-variant);
    border-radius: 0.3rem 0.3rem 2rem 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}

.experience:hover {
    background: transparent;
    border-color: var(--color-bg-variant);
    cursor: default;
}

.experience__head {
    background: #1ec935;
    padding: 2rem;
    border-radius: 0.3rem 0.3rem 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
    text-align: center;
}

.experience__head h3 {
    color: var(--color-bg);
    font-size: 1.7rem;
}

.experience__head h4 {
    color: var(--color-bg);
}

.experience__list {
    padding: 2rem;
}

.experience__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.experience__list-icon {
    color: var(--color-primary);
    margin-top: 0.3rem;
    font-size: 1.5rem;
}

.experience__list p {
    font-size: 0.9rem;

}



@media screen and (max-width: 1700px) {
    .experience__container{
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        
    }

    .experience{
        height: auto;
    }

    .experience__section {
        height: 1200px;
    }

    .experience__head {
        padding: 1.5rem;
    }
}

@media screen and (max-width: 850px) {
    .experience__container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .experience{
        height: auto;
    }

    .experience__section {
        height: 1600px;
    }
}

@media screen and (max-width: 600px) {
    .experience{
        width: 92%;
    }
}
