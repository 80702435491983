nav{
    background: transparent;
    width: max-content;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    bottom: 2rem;
    gap: 1rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
    border: 1px solid;
    border-color: var(--color-primary);
}

nav a{
    background: transparent;
    padding: 0.6rem;
    border-radius: 50%;
    display: flex;
    font-size: 1.4rem;
}

nav a:hover {
    background: var(--color-bg-variant);
    color: var(--color-primary);
}


@media screen and (max-width: 1250px), (max-height: 750px) {
    nav{
        padding: 0.5rem 1.3rem;
        bottom: 1rem;
        gap: 0.6rem;
    }
}

@media screen and (max-width: 600px) {
    nav{
        gap: 0.5rem;
        padding: 0.5rem 1.1rem;
    }
}
